.waxing-services-container {
  max-width: 1200px;
  margin: 0 auto;
}

.waxing-service-container {
  margin-top: 40px;
  display: flex;
  margin-bottom: 50px;
}

.waxing-service-image,
.waxing-service-details {
  flex: 1;
}

.waxing-service-image img {
  width: 100%;
  max-width: 557px;
  height: auto;
}

.waxing-service-details {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}

.title {
  color: #740727;
  text-align: left;
  font-size: 24px;
  font-weight: bold;
  font-family: 'Rubik', sans-serif;
}

.info {
  font-size: 17px;
  font-family: 'Rubik', sans-serif;
  text-align: left;
  margin-bottom: 30px;
  letter-spacing: 1px;
}

.custom-button {
  background-color: #1976d2;
  width: 168px;
  height: 53px;
  font-size: 18px;
  color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border-radius: 5px;
}

.other-services-container {
  max-width: 1200px;
  margin: 0 auto;
  margin-top: 50px;
}

.other-services {
  background-color: #f9f9f9;
  padding: 20px;
  border-radius: 10px;
}

.other-services h3 {
  font-size: 24px;
  color: #333;
}

.service-buttons {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-top: 20px;
}

.service-button {
  margin-bottom: 20px;
}

.service-button a {
  display: flex;
  align-items: center;
  text-decoration: none;
  color: #333;
}

.service-button a:hover {
  color: #f9c5d1;
}

.service-button span {
  margin-left: 10px;
}
@media screen and (max-width: 768px) {
  .waxing-service-container {
    flex-direction: column;
  }
  
  .waxing-service-details {
    text-align: center;
  }
  
  .waxing-service-image img {
    max-width: 100%;
    height: auto;
  }
}
