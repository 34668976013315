


nav {
    background-color: #f7f7f7;
    padding: 15px 0px; /* Adjust the padding as needed */
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 1000;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  }
  
  .navbar-left {
    font-size: 25px; /* Adjust the font size as needed */
    margin-left: 60px;
    
    color: rgb(17, 17, 17);
    font-family: 'Rubik';
  }

  .navbar-left p {
    font-weight: 400; /* Make the text bold */
}
  
.navbar-right {
  list-style-type: none;
  padding: 0;
  margin: 0;
  font-family: 'Rubik'; 
  font-size: 16px;
  margin-right: 25px; 
}

.navbar-right li {
  display: inline;
  margin-right: 22px; /* Adjust the margin as needed */
}

.navbar-right a {
  color: rgb(0, 0, 0);
  text-decoration: none;
  position: relative; /* Add position relative */
}

.navbar-right a:hover {
  text-decoration: none; /* Remove default underline */
  color: #9c6d78; /* Change text color to white */
}

/* Add underline on hover effect */
.navbar-right a::after {
  content: ''; /* Add empty content */
  position: absolute; /* Position the underline */
  left: 0;
  bottom: -2px; /* Adjust distance from text */
  width: 100%; /* Full width */
  height: 2px; /* Height of underline */
  background-color: transparent; /* Initially transparent */
  transition: background-color 0.3s ease; /* Add transition effect */
}

.navbar-right a:hover::after {
  background-color: #ffffff; /* Color of underline on hover */
}
/* Navbar Styles */

/* Other styles remain unchanged */

.hamburger {
  display: none;
}

.sidebar {
  position: fixed;
  top: 0;
  right: 0;
  height: 100vh;
  width: 250px;
  z-index: 999;
  background-color: white;
  backdrop-filter: blur(10px);
  box-shadow: -10px 0 10px rgba(0, 0, 0, 0.1);
  display: none;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  
}

.sidebar.show {
  display: flex;
}

.sidebar li {
  list-style-type: none;
  width: 100%;
}

.sidebar a {
  width: 100%;
  display: block; /* Ensure links take up full width */
  text-decoration: none; /* Remove underline */
  color: black; /* Set text color */
  padding: 10px 20px; /* Add padding for better touch area */
}

.sidebar a:hover {
  background-color: #f0f0f0; /* Change background color on hover */
}

.logo {
  height: 40px;
  width: auto;
  
}


.close-btn {
  margin-top: 20px;
  padding: 10px 20px;
  background-color: #9c6d78;
  color: #ffffff;
  border: none;
  cursor: pointer;
}

@media only screen and (max-width: 768px) {
  .navbar-right {
    display: none;
  }

  .hamburger {
    display: block;
    margin-right: 10px;
    cursor: pointer;
  }
}

  
  /* For screens smaller than 576px */
  @media only screen and (max-width: 576px) {
    .navbar-left {
      font-size: 16px;
    }
  
    .navbar-right li {
      margin-right: 5px;
    }
  }