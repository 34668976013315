/* Contact Page Styles */
.contact-title{
  margin-top: 100px;
  font-size: 36px;
  font-family: 'Rubik';
  color: #000000;
  text-align: center;
  margin-bottom: 20px;
}
.contact-page-container-unique {
  margin-top: 20px;
  padding: 0 20px;
}

.google-map-container-unique {
  margin-top: 10px;
  margin-bottom: 20px;
}

.contact-info-container-unique {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.address-info-unique,
.contact-info-unique,
.email-info-unique,
.hours-info-unique {
  display: flex;
  align-items: center;
  margin: 0 20px 20px;
}

.address-icon-unique,
.phone-icon-unique,
.email-icon-unique,
.clock-icon-unique {
  font-size: 24px;
  margin-right: 10px;
  min-width: 24px; /* Ensure icon width remains constant */
}

.address-info-unique h2,
.contact-info-unique h2,
.email-info-unique h2,
.hours-info-unique h2 {
  font-size: 1.2rem;
  margin-bottom: 5px;
}

.address-info-unique p,
.contact-info-unique p,
.email-info-unique p,
.hours-info-unique p {
  font-size: 1rem;
}

/* Footer */
/* Add your existing footer styles here */

/* Media query for screens smaller than 768px */
@media only screen and (max-width: 768px) {
  .address-info-unique,
  .contact-info-unique,
  .email-info-unique,
  .hours-info-unique {
    flex-direction: column;
    align-items: flex-start;
  }

  .address-icon-unique,
  .phone-icon-unique,
  .email-icon-unique,
  .clock-icon-unique {
    margin-right: 0;
    margin-bottom: 10px;
  }

  .address-info-unique h2,
  .contact-info-unique h2,
  .email-info-unique h2,
  .hours-info-unique h2,
  .address-info-unique p,
  .contact-info-unique p,
  .email-info-unique p,
  .hours-info-unique p {
    text-align: center;
  }
}

/* Media query for screens smaller than 576px */
@media only screen and (max-width: 576px) {
  .address-info-unique h2,
  .contact-info-unique h2,
  .email-info-unique h2,
  .hours-info-unique h2,
  .address-info-unique p,
  .contact-info-unique p,
  .email-info-unique p,
  .hours-info-unique p {
    font-size: 0.9rem;
  }
}
