
.testimonial-container {
 
 
  text-align: center;
  background-color: #f9c5d1;
  padding: 40px;
  min-height: 250px; /* Ensure a minimum height for the container */
  position: relative; /* Add relative positioning */
  margin-bottom: 0; /* Remove margin bottom */
}

.testimonial-heading {
  font-family: 'Rubik', sans-serif;
  font-size: 2rem;
  font-weight: bold;

  margin-bottom: 20px; /* Reduce margin bottom */
  color: #000000; /* Dark gray color */
}

.testimonial-slider {
  position: relative;
}

.testimonial {
  display: none;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
}

.testimonial.active {
  display: block;
}

.testimonial-text {
  font-size: 22px;
  font-family: 'Rubik', sans-serif;
  line-height: 1.5;
  margin-bottom: 20px; /* Reduce margin bottom */
  color: #1b1a1a; /* Dark gray color */
  text-align: center; /* Center align testimonial text */
}

.testimonial-name {
  font-family: 'Rubik', sans-serif;
  font-size: 1rem;
  font-style: italic;
  color: #555;
  text-align: center; /* Center align testimonial name */
}

.slider-dots {
  display: flex;
  justify-content: center;
  margin-top: 0; /* Remove top margin */
  margin-bottom: 40px; /* Add bottom margin */
}
.dot {
  height: 10px;
  width: 10px;
  background-color: rgba(0, 0, 0, 0.5);
  border-radius: 50%;
  margin: 0 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.dot.active {
  background-color: white;
}


@media screen and (max-width: 768px) {
  .testimonial-heading {
    font-size: 1.5rem; /* Reduce font size for smaller screens */
  }

  .testimonial-text {
    font-size: 18px; /* Reduce font size for smaller screens */
  }

  .testimonial-name {
    font-size: 0.9rem; /* Reduce font size for smaller screens */
  }
}

@media screen and (max-width: 576px) {
  .testimonial-heading {
    font-size: 1.2rem; /* Further reduce font size for even smaller screens */
  }

  .testimonial-text {
    font-size: 16px; /* Further reduce font size for even smaller screens */
  }

  .testimonial-name {
    font-size: 0.8rem; /* Further reduce font size for even smaller screens */
  }
}