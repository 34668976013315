

.services-container-yarr {
 margin-left: 20px;
 margin-right: 20px;
}

.services-title-yarr {
  margin-top: 100px;
    font-size: 36px;
    font-family: 'Rubik';
    color: #000000;
    text-align: center;
    margin-bottom: 20px;
}

.services-grid-yarr {
  display: grid;
  grid-template-columns: repeat(3, minmax(0, 1fr)); /* Adjusted for 3 cards per row */
  gap: 20px;
}

.services-service-card-yarr {
  height: 400px; 
  background-color: #fff;
  border-radius: 10px;
  overflow: hidden;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease;
  text-decoration: none;
  color: inherit;
  margin-bottom: 50px;
}

.services-service-card-yarr:hover {
  transform: translateY(-5px);
}

.services-service-image-container-yarr {
  width: 100%;
  height: 250px; /* Adjust height as needed */
  overflow: hidden;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}

.services-service-image-yarr {
  width: 100%;
  height: 100%;
  object-fit: contain;
  transition: transform 0.3s ease;
}

.services-service-card-yarr:hover .services-service-image-yarr {
  transform: scale(1.1);
}

.services-service-details-container-yarr {
  padding: 20px;
}

.services-service-title-yarr {
  font-family: 'Rubik', sans-serif;
  font-size: 24px;
  margin-top: 0;
  margin-bottom: 10px;
}

.services-service-details-yarr {
  font-family: 'Rubik', sans-serif;
  font-size: 16px;
  color: #666;
  line-height: 1.5;
}

@media only screen and (max-width:768px){
  
.services-grid-yarr {

  grid-template-columns: repeat(1, minmax(0, 1fr)); /* Adjusted for 3 cards per row */
 
}

}