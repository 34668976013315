.services-container {
  font-family: 'Rubik', sans-serif; 
  padding: 50px 20px;
  text-align: center;
}

.services-row {
  font-family: 'Rubik', sans-serif; 
  display: flex;
  justify-content: space-between; /* Ensure spacing between items */
  flex-wrap: wrap;
  margin-bottom: 50px; /* Increase margin for better spacing */
}

.service-item {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-decoration: none;
  overflow: hidden;
  border-radius: 20px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease;
  width: calc(23% - 20px); /* Adjusted width for 4 items per row */
  margin-bottom: 40px;
  margin-right: 20px; /* Add right margin for spacing */
  background-color: #f8f4f4; /* Soft light color */
}

.service-item:nth-child(4n) {
  margin-right: 0; /* Remove right margin for the last item in each row */
}

.service-item:hover {
  transform: translateY(-5px);
}

.service-image {
  width: 100%;
  height: 200px;
  overflow: hidden;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
}

.service-image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.service-details {
  padding: 20px;
}

.service-item h2 {
  margin: 10px 0;
  font-size: 1.6rem; /* Increase font size */
  font-weight: bold;
  color: #333;
}

.service-item p {
  margin-bottom: 15px;
  font-size: 1.2rem; /* Increase font size */
  color: #666;
}

/* Add animation for hover effect */
.service-item::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.3); /* Semi-transparent white overlay */
  z-index: 1;
  opacity: 0;
  transition: opacity 0.3s ease;
  border-radius: 20px;
}

.service-item:hover::before {
  opacity: 1;
}

.service-item:hover .service-image {
  transform: scale(1.05); /* Scale up the image on hover */
}

.service-item:hover h2,
.service-item:hover p {
  color: #ff6b81; /* Change text color on hover */
}

/* Media queries */

@media screen and (max-width: 768px) {
  .service-item {
    width: calc(50% - 20px); /* Two services per row */
    margin-right: 0;
  }

  .service-item:nth-child(2n) {
    margin-right: 0; /* Remove right margin for the last item in each row */
  }
}

@media screen and (max-width: 576px) {
  .service-item {
    width: calc(100% - 20px); /* One service per row */
    margin-right: 0;
  }
}
