/* HomeAboutUs.css */

/* Container for the entire HomeAboutUs component */
.home-about-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 50px;
    background-color: #f7f7f7; /* Light gray background color */
}

/* Content area for text */
.home-about-content {
    max-width: 65%; /* Limiting content area to 50% of the container width */
    margin-right: 50px 5%; /* Adding right margin for spacing */
}

/* Title style */
.home-title {
    font-family: 'Rubik';
    font-size: 3rem;
    font-weight: 300;
    color: #333; /* Dark gray color */
    margin-bottom: 20px; /* Adding bottom margin for spacing */
}

/* Description style */
.home-description {
    font-family: 'Rubik';
    font-size: 1.2rem;
    line-height: 1.6;
    color: #555; /* Dark gray color */
    margin-bottom: 20px; /* Adding bottom margin for spacing */
}

/* Container for certifications */
.home-certifications {
    display: flex;
    flex-wrap: wrap; /* Allow certifications to wrap to the next line if necessary */
    justify-content: space-between;
    margin-top: 35px; /* Adding top margin for spacing */
}

/* Card-like structure for certifications */
.creative-card {
    width: calc(33.33% - 20px); /* Adjust width to fit three cards in a row */
    margin: 10px; /* Add margin between cards */
    background: linear-gradient(145deg, #ffffff, #e6e6e6);
    border-radius: 20px;
    box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.1);
    overflow: hidden;
    padding: 20px;
    cursor: pointer;
    transition: transform 0.3s ease-in-out, box-shadow 0.3s ease;
    position: relative;
}

.creative-card:hover {
    transform: translateY(-10px);
    box-shadow: 0 12px 24px rgba(0, 0, 0, 0.15);
}

.creative-icon {
    max-width: 100%; /* Constrain image width to fit within the card */
    max-height: 150px; /* Set a maximum height for the images */
    display: block; /* Ensure image takes full width of its container */
    margin: 0 auto 10px; /* Center the image horizontally and add space below */
}

.creative-card h3 {
    font-family:  'Rubik';
    font-size: 18px;
    color: #333; 
    margin: 10px 0;
}

.creative-card p {
    font-size: 14px;
    color: #666;
}
.additional-line {
    font-family: 'Rubik';
    font-weight: bold;
    margin-top: 20px; /* Add margin for spacing */
    text-align: center; /* Center the text */
    border-top: 2px solid #333; /* Add a 2px solid border on top */
    padding-top: 10px; /* Add padding on top for spacing */
  }
  
  
/* Media queries */
@media screen and (max-width: 576px) {
    .home-about-content {
        max-width: 80%; /* Reduce max-width for text */
        text-align: left; /* Align text to the left */
        margin-right: 0; /* Remove right margin for spacing */
    }

    .home-about-container {
        flex-direction: column-reverse; /* Display items in reverse order */
        align-items: center; /* Center-align items */
    }

    .home-certifications {
        flex-direction: column; /* Display cards in a column */
        align-items: center; /* Center-align cards */
    }

    .creative-card {
        width: calc(80% - 20px); /* Adjust width to fit one card in a column */
        margin: 10px 0; /* Add margin between cards */
    }

    .home-about-image img {
        width: 100%; /* Adjusted width to fill the container */
        max-width: 300px; /* Limiting max width of the image */
        border-radius: 10px; /* Adding border radius for rounded corners */
        box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1); /* Adding box shadow for visual effect */
        margin-bottom: 20px; /* Add margin to separate image from text */
    }

    .home-title {
        font-size: 2rem; /* Adjusted font size */
    }

    .home-description {
        font-size: 1rem; /* Adjusted font size */
    }
}

/* Image style for the about image */
.home-about-image img {
    width: 100%;
    max-width: 400px; /* Limiting max width of the image */
    border-radius: 10px; /* Adding border radius for rounded corners */
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1); /* Adding box shadow for visual effect */
}