
/* AboutUs.css */
/* @font-face {
  font-family: 'Rubik';
  src: url('../font/static/Rubik.ttf') format('truetype');
  /* Add other font properties here if needed */
/* } */ 
.about-title {
  margin-top: 100px;
  font-size: 36px;
  font-family: 'Rubik';
  color: #000000;
  text-align: center;
  margin-bottom: 20px;
}

.about-container {
  margin-top: 30px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 50px;
  background-color: #f7f7f7; /* Light gray background color */
}

.about-content {
  max-width: 65%;
  margin-right: 50px 5%;
}

.title {
  font-family: 'Rubik';
  font-size: 3rem;
  font-weight: 300;
  color: #333; /* Dark gray color */
  margin-bottom: 20px;
}

.description {
  font-family: 'Rubik';
  font-size: 1.2rem;
  line-height: 1.6;
  color: #555; /* Dark gray color */
  margin-bottom: 20px;
}

/* Container for certifications */
.home-certifications {
  display: flex;
  flex-wrap: wrap; /* Allow certifications to wrap to the next line if necessary */
  justify-content: space-between;
  margin-top: 35px; /* Adding top margin for spacing */
}

/* Card-like structure for certifications */
.creative-card {
  width: calc(33.33% - 20px); /* Adjust width to fit three cards in a row */
  margin: 10px; /* Add margin between cards */
  background: linear-gradient(145deg, #ffffff, #e6e6e6);
  border-radius: 20px;
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.1);
  overflow: hidden;
  padding: 20px;
  cursor: pointer;
  transition: transform 0.3s ease-in-out, box-shadow 0.3s ease;
  position: relative;
}

.creative-card:hover {
  transform: translateY(-10px);
  box-shadow: 0 12px 24px rgba(0, 0, 0, 0.15);
}

.creative-icon {
  max-width: 100%; /* Constrain image width to fit within the card */
  max-height: 150px; /* Set a maximum height for the images */
  display: block; /* Ensure image takes full width of its container */
  margin: 0 auto 10px; /* Center the image horizontally and add space below */
}

.creative-card h3 {
  font-family:  'Rubik';
  font-size: 18px;
  color: #333; 
  margin: 10px 0;
}

.creative-card p {
  font-size: 14px;
  color: #666;
}
.additional-line {
  font-family: 'Rubik';
  font-weight: bold;
  margin-top: 20px; /* Add margin for spacing */
  text-align: center; /* Center the text */
  border-top: 2px solid #333; /* Add a 2px solid border on top */
  padding-top: 10px; /* Add padding on top for spacing */
}


.about-image img {
  width: 100%;
  max-width: 400px;
  border-radius: 10px;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1);
}

/* Media queries */

@media screen and (min-width: 768px) {
  .about-container {
    flex-direction: row;
    align-items: center; 
  }

  .about-content {
    max-width: 50%;
    text-align: left;
    margin-right: 50px; /* Add right margin for spacing */
  }

  .about-image {
    width: 50%;
  }
}

@media screen and (max-width: 576px) {
  .about-container {
    padding: 20px; /* Adjust padding for smaller screens */
    flex-direction: column; /* Stack elements vertically */
    align-items: center; /* Center-align elements */
  }

  .about-image {
    margin-bottom: 20px; /* Add bottom margin to image for spacing */
  }

  .title {
    text-align: center; 
    /* font-size: 16px;
    font-weight: 300; */
  }

  .about-content {
    max-width: 80%; /* Reduce max-width for text */
    text-align:start; /* Align text to the left */
    margin-bottom: 30px; /* Add bottom margin for spacing */
  }
  .home-certifications {
    flex-direction: column; /* Display cards in a column */
    align-items: center; /* Center-align cards */
}

.creative-card {
    width: calc(80% - 20px); /* Adjust width to fit one card in a column */
    margin: 10px 0; /* Add margin between cards */
}
  .certifications {
    flex-direction: column; /* Stack certifications vertically */
    align-items: center; /* Center-align certifications */
  }

  .certification {
    margin-bottom: 15px; /* Increase margin between certifications */
  }

  .certification-icon {
    width: 30px; /* Decrease icon size */
    height: 30px;
    margin-right: 10px; /* Reduce right margin */
  }

  .certification-text {
    font-size: 1rem; /* Decrease text size */
  }
}
