
*{margin:0;
  padding:0;
  box-sizing: border-box;

}
.hero {
  background-size:cover;
  background-repeat: no-repeat;
  background-position: center;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff; /* Text color */
  position: relative;
  overflow: hidden;
}

.hero img {
  min-width: 100%; /* Ensure the image fills the container */
  min-height: 100%; /* Ensure the image fills the container */
  width: auto; /* Allow the image to scale proportionally */
  height: auto; /* Allow the image to scale proportionally */
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent black overlay */
}

.hero-content {
  position: relative;
  text-align: center;
  z-index: 1;
}

.hero-title {
  font-family: 'Rubik';
  font-size: 3rem;
  margin-bottom: 10px;
  color: #fff; /* Custom font color */
  font-family: 'Dancing Script', cursive; /* Script font for the title */
}

.hero-title span {
  color: #f9c5d1; /* Custom color for the word "Rejuvenate" */
}

.hero-subtitle {
  font-family: 'Rubik';
  font-size: 2rem;
  margin-top: 0;
  color: #f9c5d1; /* Custom font color */
  /* font-family: Arial, sans-serif; Sans-serif font for the subtitle */
}

.slider-dots {
  position: absolute;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
}

.dot {
  height: 10px;
  width: 10px;
  background-color: rgba(255, 255, 255, 0.5);
  border-radius: 50%;
  margin: 0 5px;
  cursor: pointer;
}

.dot.active {
  background-color: white;
}

@media screen and (max-width: 768px) {
  .hero {
    background-size: cover; /* Adjust background size for smaller screens */
  }

  .hero img {
    max-width: 80%; /* Adjust maximum width for smaller screens */
  }

  .hero-title {
    font-size: 2.5rem;
  }

  .hero-subtitle {
    font-size: 1.8rem;
  }
}

@media screen and (max-width: 576px) {
  .hero img {
    max-width: 90%; /* Adjust maximum width for smaller screens */
  }
  .hero-title {
    font-size: 2rem;
  }

  .hero-subtitle {
    font-size: 1.5rem;
  }
}