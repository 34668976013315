/* Footer Styles */

.footer {
  background-color: #222;
  color: #fff;
  margin-top: 0; /* Remove margin top */
}

.footer-container {
  display: flex;
  justify-content: space-between;
}

.footer-column {
  flex-grow: 1;
}

.footer-section {
  margin-bottom: 10px; /* Reduce margin-bottom for closer spacing */
}

.footer-section:last-child {
  margin-bottom: 0; /* Remove margin-bottom for the last section to prevent extra spacing */
}

.footer-heading {
  color: #ffffff;
  font-weight: bold;
  font-size: 1.5rem;
  margin-bottom: 10px;
  font-family: 'Rubik';
}

.footer-contact-item {
  display: flex;
  align-items: center;
}

.footer-icon {
  font-size: 30px;
  margin-right: 15px;
}

.footer-contact-column {
  color: #ffffff; 
}

.footer-hours-column {
  color: #ffffff; 
}

.footer-contact-info {
  font-size: 1rem; /* Increased font size */
  line-height: 1.4;
  font-family: 'Rubik';
}

.footer-sub-info {
  font-size: 1rem; /* Increased font size */
  margin: 10px 0 0; /* Added top margin */
  font-family: 'Rubik';
}

.footer-bottom {
  text-align: center;
  margin-top: 20px;
}

.footer-copyright {
  font-size: 0.9rem;
  font-family: 'Rubik';
  margin-bottom: 8px;
}

/* Media query for screens smaller than 768px */
@media only screen and (max-width: 768px) {
  .footer-container {
    flex-direction: column; /* Change flex direction to column */
    align-items: center; /* Center align items */
  }

  .footer-column {
    margin-bottom: 20px; /* Add margin bottom for better alignment */
    width: 100%;
    text-align: center; /* Center align text */
  }

  .footer-address-column {
    order: 1; /* Change order of address column */
  }

  .footer-contact-column {
    order: 2; /* Change order of contact column */
  }

  .footer-hours-column {
    order: 3; /* Change order of hours column */
  }

  .footer-heading {
    text-align: center; /* Center align footer headings */
  }

  /* Center align footer sub-info paragraphs */
  .footer-sub-info {
    text-align: center;
  }

  .footer-icon {
    margin-right: 0; /* Remove right margin for icon */
  }

  /* Center align footer-section */
  .footer-section {
    align-items: center;
  }

  /* Center align footer-contact-item */
  .footer-contact-item {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

/* Media query for screens smaller than 576px */
@media only screen and (max-width: 576px) {
  .footer-column {
    align-items: center; /* Center align items */
  }

  .footer-heading {
    font-size: 1rem; /* Further reduce font size for smaller screens */
    text-align: center; /* Center align footer headings */
  }

  .footer-icon {
    font-size: 18px; /* Further reduce icon size for smaller screens */
    margin-right: 8px; /* Add back right margin for icon */
  }

  .footer-sub-info {
    font-size: 0.7rem; /* Further reduce font size for smaller screens */
    text-align: center; /* Center align footer sub-info paragraphs */
  }
}

