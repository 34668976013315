.services-container {
  max-width: 1200px;
  margin: 0 auto;
}

.hair-service-container {
  margin-top: 40px;
  display: flex;
  margin-bottom: 50px;
}

.hair-service-image {
  flex: 1;
}

.hair-service-image img {
  width: 100%;
  max-width: 557px; /* Adjusted to match the example */
  height: auto;
  border-radius: 10px; /* Adjust border-radius for rounded corners */
}

.hair-service-details {
  flex: 1;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}

.hair-service-title {
  color: #740727;
  text-align: left;
  font-size: 24px;
  font-weight: bold;
  font-family: 'Rubik', sans-serif;
}

.hair-service-info {
  font-size: 17px;
  font-family: 'Rubik', sans-serif;
  text-align: left;
  margin-bottom: 30px;
  margin-left: 30px;
  letter-spacing: 1px;
}

.hair-service-price {
  background-color: #1976d2;
  width: 168px;
  height: 53px;
  font-size: 18px;
  color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border-radius: 5px; /* Adding border radius */
}

.hair-service-other-services-container {
  max-width: 1400px;
  margin: 0 auto;
  margin-top: 50px;
}

.hair-service-other-other-services {
  background-color: #f9f9f9;
  padding: 20px;
  border-radius: 10px;
}

.hair-service-other-services h3 {
  font-size: 24px;
  color: #333;
}

.service-buttons {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-top: 20px;
}

.service-button {
  margin-bottom: 20px;
}

.service-button a {
  display: flex;
  align-items: center;
  text-decoration: none;
  color: #333;
}

.service-button a:hover {
  color: #f9c5d1;
}

.service-button span {
  margin-left: 10px;
}

@media only screen and (max-width: 600px) {
  .hair-service-container {
    flex-direction: column;
    align-items: center;
    margin-bottom: 30px;
  }

  .hair-service-image img {
    max-width: 100%;
  }

  .hair-service-details {
    text-align: center;
    margin-top: 20px;
  }

  .hair-service-title {
    font-size: 20px;
    margin-bottom: 15px;
  }

  .hair-service-info {
    font-size: 15px;
    margin-bottom: 20px;
  }

  .hair-service-price {
    width: 100%;
    height: 45px;
    font-size: 16px;
    margin-top: 20px;
  }
}
