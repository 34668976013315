.permanent-eyebrow-video-container {
    width: 60%; /* Adjust as needed */
    margin: 100px auto 0; /* Added top margin of 50px */
    padding-top: 31.25%; /* 16:5 aspect ratio (height/width * 100) */
    position: relative;
  }
  
  .permanent-eyebrow-video-container video {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 10px; /* Adjust border-radius for rounded corners */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }

  .permanent-eyebrow-service-details {
    text-align: center;
    padding: 10px;
    max-width: 800px;
    margin: 0 auto;
  }
  
  .permanent-eyebrow-title {
    color: #740727;
    text-align: left;
    font-size: 24px;
    font-weight: bold;
    font-family: 'Rubik', sans-serif;
  }
  
  .permanent-eyebrow-info {
    font-size: 17px;
    font-family: 'Rubik', sans-serif;
    text-align: left;
    margin-bottom: 30px;
    margin-left: 30px;
    letter-spacing: 1px;
  }
  
  .permanent-eyebrow-other-services-container {
    max-width: 1400px;
    margin: 0 auto;
    margin-top: 50px;
  }
  
  .permanent-eyebrow-other-services {
    background-color: #f9f9f9;
    padding: 20px;
    border-radius: 10px;
  }
  
  .permanent-eyebrow-other-services h3 {
    font-size: 24px;
    color: #333;
  }
  
  .permanent-eyebrow-service-buttons {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-top: 20px;
  }
  
  .permanent-eyebrow-service-button {
    margin-bottom: 20px;
  }
  
  .permanent-eyebrow-service-button a {
    display: flex;
    align-items: center;
    text-decoration: none;
    color: #333;
  }
  
  .permanent-eyebrow-service-button a:hover {
    color: #f9c5d1;
  }
  
  .permanent-eyebrow-service-button span {
    margin-left: 10px;
  }
  
  @media only screen and (max-width: 600px) {
    .permanent-eyebrow-video-container {
        width: 100%;
        padding-top: 56.25%; /* 16:9 aspect ratio for smaller screens */
      }

    .permanent-eyebrow-service-details {
      margin-top: 20px;
    }
  
    .permanent-eyebrow-title {
      font-size: 20px;
      margin-bottom: 15px;
    }
  
    .permanent-eyebrow-info {
      font-size: 15px;
      margin-bottom: 20px;
    }
  }
  