
.gallery-container {
    padding: 28px;
    background-color: var(--background-color);
}

.gallery-heading {
    margin-top: 80px;
    font-size: 36px;
    font-family: 'Rubik';
    color: #000000;
    text-align: center;
    margin-bottom: 20px;
}
.yarl__slide_image {

  max-width: 600px; /* Specify your desired max-width */max-height: 400px; /* Specify your desired max-height */

}

/* GalleryImages.css */
/* Add your existing styles here */

/* Media query for screens smaller than 768px */
@media only screen and (max-width: 768px) {
    .gallery-container {
      padding: 20px; /* Adjust padding for smaller screens */
    }
  
    .gallery-heading {
      font-size: 24px; /* Reduce font size for smaller screens */
      margin-top: 40px; /* Adjust margin top for smaller screens */
      margin-bottom: 10px; /* Adjust margin bottom for smaller screens */
    }
  }
  
  /* Media query for screens smaller than 576px */
  @media only screen and (max-width: 576px) {
    .gallery-heading {
      font-size: 20px; /* Further reduce font size for smaller screens */
      margin-top: 30px; /* Further adjust margin top for smaller screens */
      margin-bottom: 5px; /* Further adjust margin bottom for smaller screens */
    }
  }
  
